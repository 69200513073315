import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from "react";
import { Form, InputGroup, Media, Col, Alert, Row, FormLabel } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { makeStyles } from "@material-ui/core/styles";
import ModalDommus from "../../../../../components/Modal";
import ConclusaoChamado from "./ConclusaoChamado";
import { Icon } from "@iconify/react";
import pageDoc from "@iconify-icons/foundation/page-doc";
import searchOutlined from "@iconify-icons/ant-design/search-outlined";
import editFilled from '@iconify-icons/ant-design/edit-filled';
import "./coluna_esquerda.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { parseISO, format } from "date-fns";
import guService from "../../../../../services/GuService";
import DommusToggle from "../../../../../components/DommusToggle";
import { trackPromise } from "react-promise-tracker";
import ticketCategoria from "../../../../../services/TicketCategoriaService";
import ticketMotivo from "../../../../../services/TicketMotivoService";
import ticketStatus from "../../../../../services/TicketStatusService";
import ticketTipo from "../../../../../services/TicketTipoService";
import ticketTags from "../../../../../services/TicketTagsService";
import ticketPrioridade from "../../../../../services/TicketPrioridadeService";
import equipeService from "../../../../../services/EquipeService";
import ticketSubStatus from "../../../../../services/TicketSubStatusService";
import ProcessoService from "../../../../../services/ProcessoService";
import departamentoService from "../../../../../services/DepartamentoService";
import helper from "../../../../../helpers/format";
import Swal from "sweetalert2";
import ticketService from "../../../../../services/TicketService";
import urlHelper from "../../../../../helpers/UrlHelper";
import { errorToast } from "../../../../../components/DommusToast";
import ValidaTipoArquivo from "../../../../../helpers/ValidaTipoArquivo";
import { InternalNoteStatus } from "../../ModificarStatus/InternalNote";
import { ChamadoContext, EquipesContext } from "../../../../../components/ContextoGlobal";
import { cachearContexto } from "../../../../../helpers/cachearContexto";
import convertFilesToBase64 from "../../../../../helpers/ConvertFilesToBase64";
import { DommusBadgesResposaveis } from "../../../../../components/DommusBadgesResposaveis";
import MeioComunicacaoIcone from "../../../../../components/MeioComunicacaoIcone";
import { DommusThumbnail } from "../../../../../components/DommusThumbnail";
import { cpfMask } from "../../../../../components/InputTextMask";
import { IconePrioridade } from "../../../../../components/IconePrioridade";
import configuracaoService from "../../../../../services/ConfiguracaoService";
import { ConfiguracaoContext } from "../../../../../contexts/ConfiguracaoContext";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import DommusRequiredSpan from "../../../../../components/DommusRequiredSpan";
import TicketStatus from "../../../../../services/TicketStatusService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Coluna_esquerda({
  ticket,
  close,
  setRefresh,
  refresh,
  recarregarDadosChamado,
  setRecarregarDadosChamado
}) {

  const [chamado, setChamado] = useState(ticket);
  const [solicitante, setSolicitante] = useState(chamado.solicitante);
  const [processoBusca, setProcessoBusca] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [processo, setProcesso] = useState(
    helper.zeroPad(chamado.id_processo, 5) || ""
  );
  const [dataCadastro, setDataCadastro] = useState(chamado.criado_em || "");
  const [situacao, setSituacao] = useState(
    chamado.status.id_ticket_status || ""
  );
  const [subSituacao, setSubSituacao] = useState(
    chamado.substatus == null ? "" : chamado.substatus.id_ticket_substatus
  );
  const [assunto, setAssunto] = useState(chamado.assunto || "");
  const [descricao, setDescricao] = useState(chamado.descricao || "");
  const [tipo, setTipo] = useState(chamado.tipo.id_ticket_tipo || "");
  const [categoria, setCategoria] = useState(
    chamado.categoria.id_ticket_categoria || ""
  );
  const [motivo, setMotivo] = useState(chamado.motivo.id_ticket_motivo || "");
  const [listaArquivos, setListaArquivos] = useState([]);
  const [prioridade, setPrioridade] = useState(
    chamado.prioridade.id_ticket_prioridade || ""
  );
  const [dataLimite, setDataLimite] = useState(chamado.prazo_sla == null ? "" : chamado.prazo_sla);
  const [responsavelExecucao, setResponsavelExecucao] = useState(
    chamado.responsaveis_execucao || []
  );
  const [departamentosExecucao, setDepartamentosExecucao] = useState(
    chamado.departamentos_execucao || []
  );
  const [departamentoAutorizacao, setDepartamentoAutorizacao] = useState(
    chamado.departamentos_autorizacao || []
  );
  const [departamentoAprovacao, setDepartamentoAprovacao] = useState(
    chamado.departamentos_aprovacao || []
  );
  const [departamentoAcompanhamento, setDepartamentoAcompanhamento] = useState(
    chamado.departamentos_acompanhamento || []
  );
  const [responsavelAutorizacao, setResponsavelAutorizacao] = useState(
    chamado.responsaveis_autorizacao || []
  );
  const [responsavelAprovacao, setResponsavelAprovacao] = useState(
    chamado.responsaveis_aprovacao || []
  );
  const [acompanhamento, setAcompanhamento] = useState(
    chamado.usuarios_acompanhamento || []
  );
  const [empreendimentoNome, setEmpreendimentoNome] = useState(
    chamado?.nome_empreendimento || ""
  );
  const [primeiroProponente, setPrimeiroProponente] = useState(
    {
      "nome": chamado?.proponentes?.proponente1,
      "cpf": chamado?.proponentes?.proponente1_cpf,
    }
  );
  const [dadosEmpreendimento, setDadosEmpreendimento] = useState(
    chamado?.dados_empreendimento || null
  );
  const [tags, setTags] = useState(chamado.tags || []);

  const [listaTipos, setListaTipos] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [listaTags, setListaTags] = useState([]);
  const [listaMotivos, setListaMotivos] = useState([]);
  const [listaStatus, setListaStatus] = useState([]);
  const [listaSubStatus, setListaSubStatus] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaPrioridades, setListaPrioridades] = useState([]);
  const [listaResponsaveis, setListaResponsaveis] = useState([]);
  const [modalConclusao, setModalConclusao] = useState(false);
  const [listaDepartamentos, setListaDepartamentos] = useState([]);
  const [dadosConclusao, setDadosConclusao] = useState([]);
  const [autorizado, setAutorizado] = useState(false);
  const [aprovado, setAprovado] = useState(false);
  const [statusMudou, setStatusMudou] = useState(false);
  const [modalNotaInterna, setModalNotaInterna] = useState(false);
  const [meioComunicacao, setMeioComunicacao] = useState(chamado.origem_ticket);
  const [listaMeioComunicacao, setListaMeioComunicacao] = useState([]);
  const [habilitarEdicaoChamado, setHabilitarEdicaoChamado] = useState(false);
  const [habilitarEdicaoQualificacao, setHabilitarEdicaoQualificacao] = useState(false);
  const {listaConfiguracoes} = useContext(ConfiguracaoContext);

  const [arquivo, setArquivo] = useState([]);
  const contextoChamados = useContext(ChamadoContext)
  const contextoEquipes = useContext(EquipesContext)

  useEffect(() => {
    buscarArquivos();
    trackPromise(ticketService.buscaMeiosComunicacao()
      .then((response) => {
        setListaMeioComunicacao(Object.values(response.data));
      }).catch(() => {
        Swal.fire({
          title: 'Ooops...', 
          text: 'Ocorreu um erro ao carregar os Meios de Comunicação.', 
          icon: 'error',
          confirmButtonText: `Recarregar`
        }).then((result) => {
          if(result.isConfirmed) {
            setRecarregarDadosChamado(!recarregarDadosChamado)
          }
        })
      })
    );
  }, [recarregarDadosChamado, refresh])

  const habilitarReabrirChamado = useMemo(()=>{
    let permissaoReabrir = listaConfiguracoes.find((item) => item.chave === "tipos_usuarios_permite_reabertura_chamado");
    if(chamado?.conclusao?.data_conclusao && chamado?.status?.tipo === "F"){
      if(permissaoReabrir && permissaoReabrir?.valor?.length && permissaoReabrir.valor.includes(Number(guService.getLocalState("tipoUsuario")))){
        return true;
      }else if(!chamado?.motivo.dias_reabertura_chamado){
        return false;
      }else if(moment(chamado?.conclusao?.data_conclusao).days(chamado?.motivo.dias_reabertura_chamado) < moment()){
        return false;
      }else{
        return true;
      }
    }
    return true;
  },[chamado?.conclusao?.data_conclusao])


  useEffect(() => {
    cachearContexto(contextoChamados.ticketCategoria, contextoChamados.setTicketCategoria, ticketCategoria.index)
    cachearContexto(contextoChamados.ticketMotivo, contextoChamados.setTicketMotivo, ticketMotivo.index)
    cachearContexto(contextoChamados.ticketStatus, contextoChamados.setTicketStatus, ticketStatus.index)
    cachearContexto(contextoChamados.ticketTipo, contextoChamados.setTicketTipo, ticketTipo.index)
    cachearContexto(contextoChamados.ticketPrioridade, contextoChamados.setTicketPrioridade, ticketPrioridade.index)
    cachearContexto(contextoEquipes.usuarios, contextoEquipes.setUsuarios, equipeService.index)
    cachearContexto(contextoChamados.ticketTags, contextoChamados.setTicketTags, ticketTags.index)
    cachearContexto(contextoChamados.ticketSubStatus, contextoChamados.setTicketSubStatus, ticketSubStatus.index)
    cachearContexto(contextoEquipes.departamentos, contextoEquipes.setDepartamentos, departamentoService.index)
  }, []);

  const buscarStatusTicket = ()=>{
    trackPromise(TicketStatus.buscarStatusPorFluxoTicket(chamado.id_ticket)).then((response) => {
      setListaStatus(response?.data ?? contextoChamados.ticketStatus);
    }).catch((error)=>{
      setListaStatus(contextoChamados.ticketStatus)
    })
  }

  useEffect(() => {
    setListaDepartamentos(contextoEquipes.departamentos)
    montaListaResponsaveis(contextoEquipes.usuarios, contextoEquipes.departamentos);
  }, [contextoEquipes.departamentos])

  useEffect(() => {
    montaListaTags(contextoChamados.ticketTags)
  }, [contextoChamados.ticketTags])

  useEffect(() => {
    setListaUsuarios(contextoEquipes.usuarios)
  }, [contextoEquipes.usuarios])

  useEffect(() => {
    setListaCategorias(contextoChamados.ticketCategoria)
  }, [contextoChamados.ticketCategoria])

  useEffect(() => {
    setListaMotivos(contextoChamados.ticketMotivo)
  }, [contextoChamados.ticketMotivo])

  useEffect(() => {
    buscarStatusTicket();
  }, [chamado.id_ticket])

  useEffect(() => {
    if(listaStatus.length && situacao){
      let temp = listaStatus.find((item)=>item.id_ticket_status === Number(situacao));
      setListaSubStatus(temp?.substatus ?? []);
    }
  },[listaStatus, situacao])

  useEffect(() => {
    setListaTipos(contextoChamados.ticketTipo)
  }, [contextoChamados.ticketTipo])

  useEffect(() => {
    setListaPrioridades(contextoChamados.ticketPrioridade)
  }, [contextoChamados.ticketPrioridade])


  function buscarArquivos (){
    trackPromise(ticketService.getArquivos(chamado.id_ticket)
      .then((response) => {
        const lista_arquivos = Object.values(response.data);
        setListaArquivos(lista_arquivos);
      }).catch(() => {
        Swal.fire({
          title: 'Ooops...', 
          text: 'Ocorreu um erro ao carregar os dados do chamado.', 
          icon: 'error',
          confirmButtonText: `Recarregar`
        }).then((result) => {
          if(result.isConfirmed) {
            setRecarregarDadosChamado(!recarregarDadosChamado)
          }
        })
      })
    );
  }
  async function montaArquivo(event) {
    if (ValidaTipoArquivo.verificaTipoArquivo(event)) {
      let dadosArquivo = await convertFilesToBase64.convertFileToBase64(event);
      setArquivo(dadosArquivo);
    } else {
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo",
      });
    }
  };

  function montaListaResponsaveis(usuarios, departamentos) {
    let departamentosAr = [];
    let responsaveis = [];
    departamentos.forEach((item) => {
      departamentosAr.push({
        value: "dep-" + item.id_departamento,
        label: "Departamento: " + item.nome,
      });
    });
    usuarios.forEach((item) => {
      responsaveis.push({ value: item.id, label: item.nome });
    });
    const concat = departamentosAr.concat(responsaveis);
    setListaResponsaveis(concat);
  }
  function montaListaTags(tags) {
    let listaTags = [];
    tags.forEach((item) => {
      listaTags.push({ value: item.id_ticket_tags, label: item.nome });
    });
    setListaTags(listaTags);
  }

  function onChangeResponsavelExecucao(value) {
    verificaRegxDepart(value);
  }

  function onChangeResponsavelAutorizacao(value) {
    verificaRegxDepartAltor(value);
  }

  function onChangeResponsavelAprovacao(value) {
    verificaRegxDepartAprov(value);
  }

  function onChangeAcompanhamento(value) {
    verificaRegxDepartAcomp(value);
  }

  function onChangeTags(value) {
    let listaTagsChange = [];
    value.forEach((item) => {
      if (item["__isNew__"]) {
        let novoItem = {
          nome: item["value"],
        };
        ticketTags.store(novoItem).then(
          (response) => {
            const cadastro = response.data;
            listaTagsChange.push({
              value: cadastro.id_ticket_tags,
              label: cadastro.nome,
            });
            ticketTags.index().then((response) => {
              montaListaTags(response.data);
            });
          },
          (error) => {
            Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um erro ao inserir tag: " + error,
              icon: "error",
            });
          }
        );
      } else {
        listaTagsChange.push({ value: item.value, label: item.label });
      }
    });
    setTags(listaTagsChange);
  }
  function verificaRegxDepart(responsavel) {
    let arrDep = [];
    let arrRes = [];
    if (responsavel) {
      responsavel.map((item) => {
        if (item.label.match(/(Departamento: +[a-zA-Z0-9_.+-])/gm)) {
          arrDep.push({
            value: parseInt(item.value.split("-")[1]),
            label: item.label,
          });
        } else {
          arrRes.push(item);
        }
      });
    } else {
      return null;
    }
    setDepartamentosExecucao(arrDep);
    setResponsavelExecucao(arrRes);
  }

  function verificaRegxDepartAprov(responsavel) {
    let arrDep = [];
    let arrRes = [];
    if (responsavel) {
      responsavel.map((item) => {
        if (item.label.match(/(Departamento: +[a-zA-Z0-9_.+-])/gm)) {
          arrDep.push({
            value: item.value.split("-")[1],
            label: item.label,
          });
        } else {
          arrRes.push(item);
        }
      });
    } else {
      return null;
    }
    setDepartamentoAprovacao(arrDep);
    setResponsavelAprovacao(arrRes);
  }

  function verificaDefaultValueExec(responsavel, departamento) {
    let arrDefault = [];
    if (
      responsavel != null &&
      responsavel.length > 0 &&
      helper.isJson(responsavel)
    ) {
      JSON.parse(responsavel).map((item) => {
        arrDefault.push(item);
      });
    } 

    if (
      departamento != null &&
      departamento.length > 0 &&
      helper.isJson(departamento)
    ) {
      JSON.parse(departamento).map((item) => {
        arrDefault.push(item);
      });
    } 

    return arrDefault;
  }

  function verificaRegxDepartAcomp(responsavel) {
    let arrDep = [];
    let arrRes = [];
    if (responsavel) {
      responsavel.map((item) => {
        if (item.label.match(/(Departamento: +[a-zA-Z0-9_.+-])/gm)) {
          arrDep.push({
            value: item.value.split("-")[1],
            label: item.label,
          });
        } else {
          arrRes.push(item);
        }
      });
    } else {
      return null;
    }
    setDepartamentoAcompanhamento(arrDep);
    setAcompanhamento(arrRes);
  }

  function verificaRegxDepartAltor(responsavel) {
    let arrDep = [];
    let arrRes = [];
    if (responsavel) {
      responsavel.map((item) => {
        if (item.label.match(/(Departamento: +[a-zA-Z0-9_.+-])/gm)) {
          arrDep.push({
            value: item.value.split("-")[1],
            label: item.label,
          });
        } else {
          arrRes.push(item);
        }
      });
    } else {
      return null;
    }
    setDepartamentoAutorizacao(arrDep);
    setResponsavelAutorizacao(arrRes);
  }

  function alteracaoTipoChamado(){
    Swal.fire({
      titleText: 'O "Tipo de Demanda" do chamado foi alterado!',
      text: `O fluxo de etapas atual será perdido!`,
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      showDenyButton: false,
      focusConfirm: false,
      confirmButtonText: "Continuar!",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-secondary mr-3",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        atualizaChamado();
      }else{
        return;
      }
    })
  }

  function atualizaChamado() {
    let dados = {
      id_ticket_status: situacao,
      assunto: assunto,
      descricao: descricao,
      id_ticket_tipo: tipo,
      id_processo: processo,
      id_ticket_categoria: categoria,
      id_ticket_motivo: motivo,
      id_ticket_prioridade: prioridade,
      departamentos_execucao: departamentosExecucao,
      departamentos_autorizacao: departamentoAutorizacao,
      departamentos_aprovacao: departamentoAprovacao,
      departamentos_acompanhamento: departamentoAcompanhamento,
      responsaveis_execucao: responsavelExecucao,
      responsaveis_autorizacao: responsavelAutorizacao,
      responsaveis_aprovacao: responsavelAprovacao,
      usuarios_acompanhamento: acompanhamento,
      origem_ticket: meioComunicacao,
      tags: tags,
      dados_arquivo: arquivo,
      id_ticket_substatus: subSituacao,
    };

    if(!categoria){
      Swal.fire({
        titleText: "Ooops...",
        text: "Campo de CATEGORIA não foi selecionado",
        icon: "warning",
      });
      return;
    }else if(!tipo){
      Swal.fire({
        titleText: "Ooops...",
        text: "Campo de TIPO não foi selecionado",
        icon: "warning",
      });
      return;
    }else if(!motivo){
      Swal.fire({
        titleText: "Ooops...",
        text: "Campo de TIPO não foi selecionado",
        icon: "warning",
      });
      return;
    }


    setDadosConclusao(dados);
    if (dados.id_ticket_status == "5" && chamado.status.id_ticket_status != 5) {
      setModalConclusao(true);
    } else {

      if(processoBusca !== "" && processoBusca.replace(/^0+(?=\d)/, '') !== chamado?.id_processo.toString() && dadosEmpreendimento){
        if (primeiroProponente !== null && primeiroProponente !== "") {
          Swal.fire({
            titleText: `Alterar`,
            text: `Você deseja alterar o chamado ${helper.zeroPad(
              chamado.id_ticket,
              7
            )} para o processo ${processoBusca}?`,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            showDenyButton: true,
            focusConfirm: false,
            confirmButtonText: "Sim!",
            denyButtonText: "Não.",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-primary mr-3",
              cancelButton: "btn btn-secondary mr-3",
              denyButton: "btn btn-danger mr-3",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {     
              dados.id_processo = processoBusca;
              dados.dados_empreendimento = dadosEmpreendimento;
              trackPromise(
                ticketService.update(chamado.id_ticket, dados).then(
                  (response) => {            
                    Swal.fire({
                      titleText: "Chamado atualizado",
                      text: "Chamado atualizado com sucesso!",
                      icon: "success",
                    }).then(() => {          
                        window.location.reload();
                    });
                  },
                  (error) => {
                    Swal.fire({
                      titleText: "Ooops...",
                      text: "Ocorreu um erro ao salvar o chamado: " + error,
                      icon: "error",
                    });
                  }
                )
              );
            } else if (result.isDenied) {
              return;
            } else {
              return;
            }
          });
        }
      }else{
        trackPromise(
          ticketService.update(chamado.id_ticket, dados).then(
            (response) => {            
              Swal.fire({
                titleText: "Chamado atualizado",
                text: "Chamado atualizado com sucesso!",
                icon: "success",
              }).then(() => {          
                if(response?.data?.responsaveis_autorizacao){
                  setResponsavelAutorizacao(response?.data?.responsaveis_autorizacao);
                }
                if(response?.data?.resposaveis_aprovacao){
                  setResponsavelAprovacao(response?.data?.resposaveis_aprovacao);
                }
                if(response?.data?.responsaveis_execucao){
                  setResponsavelExecucao(response?.data?.responsaveis_execucao);
                }
                if(response?.data?.usuarios_acompanhamento){
                  setAcompanhamento(response?.data?.usuarios_acompanhamento); 
                }
                setPrimeiroProponente(response?.data?.proponentes?.proponente1_nome ?? ""); 
                setEmpreendimentoNome(response?.data?.nome_empreendimento ?? "")
                atualizarChamado(response?.data);
                buscarStatusTicket();
              });
            },
            (error) => {
              Swal.fire({
                titleText: "Ooops...",
                text: "Ocorreu um erro ao salvar o chamado: " + error,
                icon: "error",
              });
            }
          )
        );
      }      
    }
  }

  function atualizarChamado(newChamado){
    setArquivo([]);
    buscarArquivos();
    setChamado(newChamado);
    setRefresh((current)=>!current);
    if(habilitarEdicaoChamado){
      setHabilitarEdicaoChamado(false);
    }
    if(habilitarEdicaoQualificacao){
      setHabilitarEdicaoQualificacao(false);
    }
  }

  function NotaInterna() {

    const alterarEtapaStatus = guService.getLocalState("config_alterar_status");

    if ( alterarEtapaStatus === "1" && statusMudou )
    {
        Swal.fire
        ({
            title: 'Preencha a Nota Abaixo',
            icon: 'info',
            confirmButtonText: 'Ok ',
            showCloseButton: true,
            customClass: {
              confirmButton: 'btn btn-primary mr-3',
            },
            buttonsStyling: false
        })
        .then((result) => {
          if ( result.isConfirmed ) 
          {
            setModalNotaInterna(true);
          }
        })
    }
    else 
    {
      if((Number(tipo) !== Number(chamado?.tipo?.id_ticket_tipo)) && chamado?.fluxo_etapa){
        alteracaoTipoChamado();
      }else{
        atualizaChamado();
      }
    }
  }

  function deletarArquivo(id) {
    trackPromise(
      ticketService.deletarArquivos(id).then(
        (res) => {
          Swal.fire({
            titleText: "Excluído!",
            text: "Arquivo excluído com sucesso.",
            icon: "success",
          }).then(() => {
            close();
          });
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao excluír o arquivo: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  function handleBuscaProcesso() {
    ProcessoService.buscarDadosProcesso(processoBusca).then((res) => {
      if (res.data.proponente1_nome != null) {
        setEmpreendimentoNome(`${res?.data?.empreendimento_nome} - ${res?.data?.unidade_descricao ?? "Não encontrado"}`);
        setPrimeiroProponente(res?.data?.proponente1_nome);
        setDadosEmpreendimento({
          "id_empreendimento": res?.data?.processo_empreendimento,
          "descricao_unidade": res?.data?.unidade_descricao,
          "nome_empreendimento": res?.data?.empreendimento_nome
          }
        );
      }else{
        Swal.fire({
          titleText: "Ooops...",
          text: "Processo não encotrado!",
          icon: "error",
        });
      }
    });
  }

  const buscarNomeStatus = useCallback((situacao)=>{
    if(listaStatus.length){
      let status = listaStatus?.find((item)=>{
        return item?.id_ticket_status.toString() === situacao?.toString()
      })
      if(status){
        return status?.nome;
      }else{
        status = listaStatus.find((item)=>{
          return item?.id_ticket_status === 6
        })
        if(status){
          return status?.nome;
        }else{
          return " --- ";
        }
      }
    }else{
      return " --- ";
    }
  }, [listaStatus])

  const buscarNomeSubStatus = useCallback((situacao)=>{
    let status = listaSubStatus.find((item)=>{
      return item?.id_ticket_substatus.toString() === situacao?.toString()
    })
    if(status){
      return status?.nome;
    }else{
      return " --- ";        
    }
  },[listaSubStatus]);

  const buscarNomeTipo = useCallback((tipo)=>{
    let tipoEncontrado = listaTipos.find((item)=>{
      return item?.id_ticket_tipo.toString() === tipo?.toString()
    })
    if(tipoEncontrado){
      return tipoEncontrado?.nome;
    }else{
      return " --- ";        
    }
  },[listaTipos]);

  const buscarNomeCategoria = useCallback((categoria)=>{
    let encontrado = listaCategorias.find((item)=>{
      return item?.id_ticket_categoria.toString() === categoria.toString()
    })
    if(encontrado){
      return encontrado?.nome;
    }else{
      return " --- ";        
    }
  },[listaCategorias]);

  const buscarNomeMotivo = useCallback((motivo)=>{
    let encontrado = listaMotivos.find((item)=>{
      return item?.id_ticket_motivo.toString() === motivo.toString()
    })
    if(encontrado){
      return encontrado?.nome;
    }else{
      return " --- ";        
    }
  },[listaMotivos]);

  const buscarNomePrioridade = useCallback((prioridade)=>{
    let encontrado = listaPrioridades.find((item)=>{
      return item?.id_ticket_prioridade.toString() === prioridade.toString()
    })
    if(encontrado){
      return encontrado?.nome;
    }else{
      return " --- ";        
    }
  },[listaMeioComunicacao]);

  const resolverTags = useCallback((tags)=>{
    return tags != null
            ? tags.length > 0
              ? helper.isJson(tags)
                ? JSON.parse(tags)
                : tags
              : []
            : []

  },[]);

  const arquivosThumb = useMemo(() =>{
    return (
      listaArquivos.map((url, index) => (
        <DommusThumbnail key={index} url={url.link} />
      ))      
    )
  },[listaArquivos]);

  const camposChamadoEdicao = ()=>{
    return (
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Processo:</div>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Processos"
                  name="opv"
                  defaultValue={processo}
                  onChange={(event) => {
                    setProcessoBusca(event.target.value);
                    setDadosEmpreendimento(null);
                    setPrimeiroProponente(null)
                    setEmpreendimentoNome(null);
                  }}
                  disabled={disabled}
                />
                {(chamado.id_usuario_solicitante == guService.getLocalState("idUsuario")) &&
                  <InputGroup.Append>
                    <a
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        if (disabled) {
                          setDisabled(false);
                        } else {
                          if(processoBusca !== ""){
                            handleBuscaProcesso();
                          }
                        }
                      }}
                    >
                      {disabled ? 
                        <Icon
                          icon={editFilled}
                          color="var(--dommus-color)"
                          width="1.5rem"
                          height="1.5rem"
                        />
                        :
                        <Icon
                          icon={searchOutlined}
                          color="var(--dommus-color)"
                          width="1.5rem"
                          height="1.5rem"
                        />
                      }
                    </a>
                  </InputGroup.Append>
                }
              </InputGroup>
            </Form.Group>
          </Col>    
          <Col>
            <Form.Group>
              <div className="inputLabel">Origem:</div>
              <Form.Control
                as="select"
                className="selectMotivo"
                value={meioComunicacao ?? "selecionar"}
                name="motivo"
                onChange={(event) => {
                  setMeioComunicacao(event.target.value);
                }}
              >
                <option value="selecionar"> Selecionar </option>
                {listaMeioComunicacao.map((value, index) => (
                  <option
                    key={index}
                    value={value.slug}
                  >
                    {value.slug.toUpperCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>              
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Empreendimento\Unidade:</div>
                <Form.Control
                  aria-label="Solicitante"
                  name="solicitante"
                  value={empreendimentoNome ?? "Não atribuido"}
                  onChange={() => {}}
                  disabled
                />
            </Form.Group>
          </Col> 
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">1º Proponente:</div>
                <Form.Control
                  aria-label="Solicitante"
                  name="solicitante"
                  value={primeiroProponente?.nome ?? " --- "}
                  onChange={() => {}}
                  disabled
                />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Solicitante:</div>
                <Form.Control
                  aria-label="Solicitante"
                  name="solicitante"
                  value={solicitante === null ? "" : solicitante?.nome}
                  onChange={() => {}}
                  disabled
                />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <div className="inputLabel">Data do abetura:</div>
              <Form.Control
                className="dataChamado"
                type="datetime"
                id="date-input"
                name="data_cadastro"
                disabled={true}
                value={
                  dataCadastro === null
                    ? ""
                    : format(parseISO(dataCadastro), "dd/MM/yyyy' às ' HH:mm")
                }
                onChange={() => {}}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
                <div className="inputLabel">Etapa <DommusRequiredSpan/></div>
              {!habilitarReabrirChamado && 
                <ReactTooltip id="situacao-tooltip" place="top" type="dark" effect="solid">
                  Fora do período de reabertura do chamados
                </ReactTooltip>              
              }
              <Form.Control
                data-tip data-for="situacao-tooltip"
                as="select"
                className="selectSituacao"
                value={situacao === null ? "6" : situacao}
                name="situacao"
                onChange={(event) => {
                  setSituacao(event.target.value);
                  setSubSituacao(null);
                  setStatusMudou(true);
                }}
                disabled={!habilitarReabrirChamado}
              >
                {listaStatus.filter((item)=>!item.concluido_em || item.id_ticket_status === Number(chamado.id_ticket_status)).map((value, index) => (
                  <option
                    key={index}
                    value={value.id_ticket_status}
                  >
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <div className="inputLabel">Status</div>
              <Form.Control
                as="select"
                className="selectSubSituacao"
                value={subSituacao == null ? "" : subSituacao}
                name="subSituacao"
                disabled={!habilitarReabrirChamado}
                onChange={(event) => {
                  setSubSituacao(event.target.value);
                }}
              >
                <option value="selecionar">Selecionar </option>
                {listaSubStatus.filter((item)=> !item.concluido_em || item.id_ticket_substatus === Number(chamado.id_ticket_substatus)).map((value, index) => (
                  <option key={index} value={value.id_ticket_substatus}>
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Assunto:</div>
              <Form.Control
                as="textarea"
                rows={3}
                name="assunto"
                disabled={true}
                value={assunto === null ? "" : assunto}
                onChange={() => {}}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Descrição</div>
              <Form.Control
                as="textarea"
                rows={3}
                name="descricao"
                disabled={true}
                value={descricao === null ? "" : descricao}
                onChange={() => {}}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Tipo <DommusRequiredSpan/></div>
              <Form.Control
                as="select"
                className="selectTipo"
                value={tipo === null ? "selecionar" : tipo}
                name="tipo"
                onChange={(event) => {
                  setTipo(event.target.value);
                  setCategoria("");
                  setMotivo("");
                }}
              >
                <option value="selecionar"> Selecionar </option>
                {listaTipos.map((value, index) => (
                  <option
                    key={index}
                    value={value.id_ticket_tipo}
                  >
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Categoria<DommusRequiredSpan/></div>
              <Form.Control
                as="select"
                className="selectCategoria"
                value={categoria === null ? "selecionar" : categoria}
                name="categoria"
                onChange={(event) => {
                  setMotivo("");
                  setCategoria(event.target.value);                  
                }}
              >
                <option value="selecionar"> Selecionar </option>
                {listaCategorias.map((value, index) => (
                  <option
                    key={index}
                    value={value.id_ticket_categoria}
                  >
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Motivo <DommusRequiredSpan/></div>
              <Form.Control
                as="select"
                className="selectMotivo"
                value={motivo === null ? "selecionar" : motivo}
                name="motivo"
                onChange={(event) => {
                  setMotivo(event.target.value);      
                  let motivoSelecionado = listaMotivos.find((motivo) => motivo.id_ticket_motivo === Number(event.target.value));
                  if(motivoSelecionado){
                    setCategoria(motivoSelecionado?.id_ticket_categoria ?? categoria);
                    setTipo(motivoSelecionado?.id_ticket_tipo ?? tipo);
                    setPrioridade(motivoSelecionado?.id_ticket_prioridade ?? prioridade)
                  }
                }}
              >
                <option value="selecionar"> Selecionar </option>
                {listaMotivos.filter((item) => categoria === "" || item.id_ticket_categoria === Number(categoria)).map((value, index) => (
                  <option
                    key={index}
                    value={value.id_ticket_motivo}
                  >
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>  
          </Col>
        </Row>    
        <Row>
          <Col>
            <Form.Group>
              <DommusToggle
                title={`Arquivos (Total: ${listaArquivos.length})`}
                toggle={listaArquivos.length > 0 ? true : false}
              >
                <ul className="list-unstyled">
                  {listaArquivos.map((url, i) => (
                    <Media
                      key={i}
                      as="li"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        marginBottom: 5,
                      }}
                    >
                      <Icon
                        icon={pageDoc}
                        color="var(--dommus-color)"
                        width="1.1rem"
                        height="1.1rem"
                      />
                      <Media.Body>
                        <a
                          onClick={() => {
                            if (url.link.match(/https/gi)) {
                              urlHelper.download(url.link);
                            } else {
                              var newstr = url.link.replace(
                                /http/gi,
                                "https"
                              );
                              urlHelper.download(newstr);
                            }
                          }}
                        >
                          <Form.Label
                            style={{ marginBottom: 0, cursor: "pointer" }}
                          >
                            Documento {i}
                          </Form.Label>
                        </a>
                      </Media.Body>
                      <FontAwesomeIcon
                        onClick={() => deletarArquivo(url.id_ticket_arquivo)}
                        icon={faTrash}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginLeft: 5,
                        }}
                      />
                    </Media>
                  ))}
                </ul>
              </DommusToggle>
            </Form.Group>
          </Col>
        </Row> 
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Enviar arquivos</div>
              <Form.Control
                className="form-control-file"
                id="file"
                name="file"
                accept="application/pdf, image/jpg, image/png, image/jpeg"
                type="file"
                onChange={(e) => {montaArquivo(e.target.files)}}
                multiple
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    )
  }

  const chamadoInfo = ()=>{
    return (
      <div className="informacoes">
        <Row>
          <Col xs={10} sm={10} lg={10} >
            <div className="item-info">
              <label>{chamado.tipo_chamado == 'U' && 'Cliente' || 'Empreendimento'}:</label>
              <span onClick={()=>{
                  if(chamado.tipo_chamado == 'U') {
                    window.open(guService.getLocalState("linkVendas") +  "index_ui.php?mgr=MQ==&ui=NjM=&id_processo="  + chamado?.id_processo);
                  }
                }}
                className="processo-link"
              >
                <span>{chamado.tipo_chamado == 'U' && `#${chamado?.id_processo.toString().padStart(5, '0')}` || ''}</span> 
                {chamado?.dados_empreendimento?.descricao_unidade && 
                  <span>&nbsp;|&nbsp;{chamado?.dados_empreendimento?.descricao_unidade}</span>
                }
                {chamado?.dados_empreendimento?.nome_empreendimento &&
                  <span>{`${chamado?.tipo_chamado == 'U' && ' | ' || ''} ${chamado?.dados_empreendimento?.nome_empreendimento}`}</span>
                }
                {chamado?.proponentes?.proponente1 &&
                  <span>&nbsp;|&nbsp;{`${chamado?.proponentes?.proponente1} ${chamado?.proponentes?.proponente1_cpf ? `(${cpfMask(chamado?.proponentes?.proponente1_cpf)})` : ''}`}</span>
                }
              </span>        
            </div>
          </Col>                   
          <Col xs={2} sm={2} lg={2} >
            {chamado.origem_ticket && 
              <MeioComunicacaoIcone midia={chamado.origem_ticket} tooltipPrefix="Origem do chamado: " className={"icone-midia"} />
            }
          </Col>
        </Row>        
        <Row className="row-gap">
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Solicitante:</label>
              <span>{chamado?.solicitante?.nome ?? ""}</span>        
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Data de Abertura:</label>
              <span>{ chamado.criado_em === null
                        ? ""
                        : format(parseISO(chamado.criado_em), "dd/MM/yyyy' às ' HH:mm")
                    }
              </span>        
            </div>
          </Col>
        </Row>
        <Row className="row-gap">
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Etapa:</label>
              <span>{buscarNomeStatus(chamado?.status?.id_ticket_status)}</span>        
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Status:</label>
              <span>{buscarNomeSubStatus(chamado?.substatus?.id_ticket_substatus)}</span>        
            </div>
          </Col>
        </Row>      
        <Row className="row-gap">
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Tipo de Demanda:</label>
              <span>{buscarNomeTipo(chamado?.tipo.id_ticket_tipo)}</span>        
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="item-info">
              <label>Categoria:</label>
              <span>{buscarNomeCategoria(chamado?.categoria?.id_ticket_categoria)}</span>        
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item-info">
              <label>Motivo:</label>
              <span>{buscarNomeMotivo(chamado?.motivo?.id_ticket_motivo)}</span>        
            </div>
          </Col>
        </Row>       
        <Row>
          <Col>
            <div className="item-info">
              <label>Assunto:</label>
              <span>{chamado?.assunto ?? " --- "}</span>        
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item-info">
              <label>Descrição:</label>
              <span>{chamado?.descricao ?? " --- "}</span>        
            </div>
          </Col>
        </Row>
        <Row>
          <Col>          
            <div className="item-info">
              <label className="mb-2">Anexos:</label>
              <div className="files-section">
                {arquivosThumb}
              </div>             
            </div>
          </Col>
        </Row>
      </div>
    );
  }


  const camposQualificacaoEdicao = ()=>{
    return (
      <Form>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Prioridade</div>
              <Form.Control
                as="select"
                className="selectPrioridade"
                value={prioridade === null ? "selecionar" : prioridade}
                name="prioridade"
                onChange={(event) => {
                  setPrioridade(event.target.value);
                }}
              >
                <option value="selecionar"> Selecionar </option>
                {listaPrioridades.map((value) => (
                  <option
                    key={value.id_ticket_prioridade}
                    value={value.id_ticket_prioridade}
                  >
                    {value.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <div className="inputLabel">Data Limite</div>
              <Form.Control
                className="dataLimite"
                type="text"
                id="date-input"
                name="dataLimite"
                readOnly
                value={
                  dataLimite
                    ? format(parseISO(dataLimite), "dd/MM/yyyy' às 'HH:mm")
                    : "dd/mm/yyyy"
                }
                onChange={() => {}}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Responsável Autorização:</div>
              <Select
                isMulti
                options={listaResponsaveis}
                defaultValue={verificaDefaultValueExec(
                  responsavelAutorizacao,
                  departamentoAutorizacao
                )}
                className="basic-multi-select"
                placeholder="Selecione"
                onChange={onChangeResponsavelAutorizacao}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Responsável Execução: </div>
              <Select
                isMulti
                placeholder="Selecione"
                options={listaResponsaveis}
                defaultValue={verificaDefaultValueExec(
                  responsavelExecucao,
                  departamentosExecucao
                )}
                className="basic-multi-select"
                onChange={onChangeResponsavelExecucao}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Responsável Aprovação:</div>
              <Select
                isMulti
                defaultValue={verificaDefaultValueExec(
                  responsavelAprovacao,
                  departamentoAprovacao
                )}
                options={listaResponsaveis}
                className="basic-multi-select"
                placeholder="Selecione"
                onChange={onChangeResponsavelAprovacao}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Acompanhamento/Seguidores: </div>
              <Select
                isMulti
                defaultValue={verificaDefaultValueExec(
                  acompanhamento,
                  departamentoAcompanhamento
                )}
                options={listaResponsaveis}
                className="basic-multi-select"
                placeholder="Selecione"
                onChange={onChangeAcompanhamento}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <div className="inputLabel">Tags</div>
              {
                <CreatableSelect
                  defaultValue={
                    tags != null
                      ? tags.length > 0
                        ? helper.isJson(tags)
                          ? JSON.parse(tags)
                          : tags
                        : []
                      : []
                  }
                  isMulti
                  options={listaTags}
                  className="basic-multi-select"
                  placeholder="Selecione"
                  onChange={(event) => onChangeTags(event)}
                />
              }
            </Form.Group>
          </Col>
        </Row>        
      </Form>
    );
  }

  const qualificacaoInfo = ()=>{
    return (
      <div className="informacoes">
        <Row>
          <Col xs={6} lg={6} >
            <div className="item-info">
              <label>Prioridade:</label>
              <span style={{display: "flex", gap: "10px", alignItems: "center"}}>{buscarNomePrioridade(chamado?.prioridade?.id_ticket_prioridade)} <IconePrioridade prioridade={buscarNomePrioridade(chamado?.prioridade?.id_ticket_prioridade)}/></span>        
            </div>
          </Col>
          <Col xs={6} lg={6}>
            <div className="item-info">
              <label>Data Limite:</label>
              <span>{ chamado?.prazo_sla
                      ? format(parseISO(chamado?.prazo_sla ?? ""), "dd/MM/yyyy' às 'HH:mm")
                      : "dd/mm/yyyy"
                    }
              </span>        
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="item-info">
              <label>Resposáveis Autorização:</label>              
                <DommusBadgesResposaveis items={verificaDefaultValueExec(
                                                chamado?.responsaveis_autorizacao ?? [],
                                                chamado.departamentos_autorizacao ?? []
                                          )}                   
                />                    
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <div className="item-info">
              <label>Resposáveis Execução:</label>
              <DommusBadgesResposaveis items={ verificaDefaultValueExec(
                                                chamado.responsaveis_execucao || [],
                                                chamado.departamentos_execucao || []
                                              )}
              />
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <div className="item-info">
              <label>Resposáveis Aprovação:</label>
              <DommusBadgesResposaveis items={verificaDefaultValueExec(
                                          chamado.responsaveis_aprovacao || [],
                                          chamado.departamentos_aprovacao || []
                                        )}    
              />        
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <div className="item-info">
              <label>Acompanhamento/Seguidores:</label>
              <DommusBadgesResposaveis items={verificaDefaultValueExec(
                                                chamado.usuarios_acompanhamento || [],
                                                chamado.departamentos_acompanhamento || []
                                              )}
              />        
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <div className="item-info">
              <label>Tags:</label>
              <DommusBadgesResposaveis items={ resolverTags(chamado?.tags || []) }/>        
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const conclusaoInfo = ()=>{
    var url = chamado?.conclusao?.arquivo;
    return (
      <div className="informacoes">
      <Row>
        <Col xs={12} lg={12} >
          <div className="item-info">
            <label>Solução:</label>
            <span style={{display: "flex", gap: "10px", alignItems: "center"}}>
              {chamado?.conclusao?.id_ticket_conclusao_tipo?.nome}
            </span>        
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} lg={6}>
          <div className="item-info">
            <label>Usuário Responsável:</label>
            <span>{ chamado?.conclusao?.usuario_responsavel ?? ""}
            </span>        
          </div>
        </Col>
        <Col xs={6} lg={6}>
          <div className="item-info">
            <label>Data Conclusão:</label>
            <span>{ chamado?.conclusao?.data_conclusao
                    ? format(parseISO(chamado?.conclusao?.data_conclusao ?? ""), "dd/MM/yyyy' às 'HH:mm")
                    : "dd/mm/yyyy"
                  }
            </span>        
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="item-info">
            <label>Resposta</label>              
              <span>
                {chamado?.conclusao?.descricao}
              </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="item-info">
            <label>Arquivo:</label>
            {url ? 
              <Media
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  marginBottom: 5,
                }}
              >
                <Icon
                  icon={pageDoc}
                  color="var(--dommus-color)"
                  width="1.1rem"
                  height="1.1rem"
                />
                <Media.Body>
                  <a
                    onClick={() => {
                      if (url.link.match(/https/gi)) {
                        urlHelper.download(url.link);
                      } else {
                        var newstr = url.link.replace(
                          /http/gi,
                          "https"
                        );
                        urlHelper.download(newstr);
                      }
                    }}
                  >
                    <Form.Label
                      style={{ marginBottom: 0, cursor: "pointer" }}
                    >
                      Documento
                    </Form.Label>
                  </a>
                </Media.Body>
                <FontAwesomeIcon
                  onClick={() => deletarArquivo(url.id_ticket_arquivo)}
                  icon={faTrash}
                  style={{
                    color: "red",
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                />
              </Media>
              :
              <Alert variant="info">Nenhum arquivo carregado!</Alert>
            }
          </div>
        </Col>
      </Row>       
    </div>
    )
  } 


  const handleCancelarEdicaoChamado = ()=>{

    setProcesso(chamado?.id_processo);
    setMeioComunicacao(chamado?.origem_ticket);
    setEmpreendimentoNome(chamado?.nome_empreendimento);
    setPrimeiroProponente({
      "nome": chamado?.proponentes?.proponente1,
      "cpf": chamado?.proponentes?.proponente1_cpf,
    });
    setSolicitante(chamado?.solicitante);
    setSituacao(chamado?.status.id_ticket_status);
    setSubSituacao(chamado?.substatus?.id_ticket_substatus ?? "");
    setAssunto(chamado?.assunto ?? "");
    setDescricao(chamado?.descricao ?? "");
    setTipo(chamado?.tipo.id_ticket_tipo ?? "");
    setCategoria(chamado?.categoria?.id_ticket_categoria ?? "");
    setMotivo(chamado?.motivo?.id_ticket_motivo ?? "");

    setHabilitarEdicaoChamado(false)
  }

  const handleCancelarEdicaoQualificacao = ()=>{

    setPrioridade(chamado?.prioridade?.id_ticket_prioridade);
    setResponsavelAutorizacao(chamado?.responsaveis_autorizacao ?? []);
    setDepartamentoAutorizacao(chamado.departamentos_autorizacao ?? []);
    setResponsavelAprovacao(chamado?.responsaveis_aprovacao ?? []);
    setDepartamentoAprovacao(chamado?.departamentos_aprovacao ?? []);
    setResponsavelExecucao(chamado?.responsaveis_execucao ?? []);
    setDepartamentosExecucao(chamado?.departamentos_execucao ?? []);
    setAcompanhamento(chamado?.usuarios_acompanhamento ?? [])
    setDepartamentoAcompanhamento(chamado?.departamentos_acompanhamento ?? []);
    setTags(chamado?.tags ?? [])
    setHabilitarEdicaoQualificacao(false)
  }


  return (
    <div id="esquerda" style={{maxHeight: (habilitarEdicaoChamado || habilitarEdicaoQualificacao) ? "80vh" : "90vh"}}>
      <fieldset>
        <legend>
          Dados do Chamado
        </legend>
        { habilitarEdicaoChamado ?          
          <button className="btn-campos cancelar-edicao" aria-label="Cancelar Edição" onClick={()=>{handleCancelarEdicaoChamado()}}>
            <FontAwesomeIcon icon={faTimes}/>
          </button> :
          <button className="btn-campos habilitar-edicao" aria-label="Habilitar Edição" onClick={()=>{setHabilitarEdicaoChamado(true)}}>
            <FontAwesomeIcon icon={faEdit}/>
          </button>
        }
        {habilitarEdicaoChamado ?
          camposChamadoEdicao() :  
          chamadoInfo()
        }
      </fieldset>
      <fieldset>
        <legend>Qualificação</legend>
        { habilitarEdicaoQualificacao ?          
          <button className="btn-campos cancelar-edicao" onClick={()=>{handleCancelarEdicaoQualificacao()}}>
            <FontAwesomeIcon icon={faTimes}/>
          </button> :
          <button className="btn-campos habilitar-edicao" onClick={()=>{setHabilitarEdicaoQualificacao(true)}}>
            <FontAwesomeIcon icon={faEdit}/>
          </button>
        }
        {habilitarEdicaoQualificacao ?
          camposQualificacaoEdicao() :  
          qualificacaoInfo()
        }
        
      </fieldset>
      {(chamado?.conclusao && chamado?.status?.tipo === "F") &&
        <fieldset>
          <legend>Conclusão</legend>
          {conclusaoInfo()}          
        </fieldset>
      }
      {(habilitarEdicaoChamado || habilitarEdicaoQualificacao) && 
        <div className="rodapeSalvar">
          <button
              onClick={() => {
                NotaInterna();
              }}
              className="btn btn-primary btn-salvar-chamado"
              style={{ marginRight: 20, alignSelf: "center" }}
            >
              Salvar
            </button>
        </div>
      }

      <ModalDommus
        open={modalConclusao}
        close={() => {
          setModalConclusao(false);
        }}
        titulo="Conclusão do chamado"
        size={"md"}
        content={
          <ConclusaoChamado
            close={() => {
              setModalConclusao(false);
            }}
            setRefresh={setRefresh}
            refresh={refresh}
            chamado={chamado}
            dados={dadosConclusao}
            atualizarChamado={atualizarChamado}
          />
        }
      />
    <ModalDommus
        open={modalNotaInterna}
        close={() => {
          setModalNotaInterna(false);
        }}
        content={
          <InternalNoteStatus
              id_ticket={chamado.id_ticket}
              closeModal={() => setModalNotaInterna(false)}
              statusMudou={() => setStatusMudou(false)}
              atualizaChamado={atualizaChamado}
          />
        }
        titulo="Nota Interna"
        size={"md"}
      />
    </div>
  );
}
