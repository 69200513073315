import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Form } from "react-bootstrap";
import "./index.css";
import Select from "react-select";
import DommusRequiredSpan from "../../../../../../components/DommusRequiredSpan";
import ticketMotivo from "../../../../../../services/TicketMotivoService";
import ticketService from "../../../../../../services/TicketService";
import ticketConclusaoService from "../../../../../../services/TicketConclusaoService";
import guService from "../../../../../../services/GuService";
import { useForm } from "react-hook-form";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import { errorToast } from "../../../../../../components/DommusToast";
import ValidaTipoArquivo from "../../../../../../helpers/ValidaTipoArquivo";
import Swal from "sweetalert2";
import { DommusAlert } from "../../../../../../components/DommusAlert";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

export default function ConclusaoChamado({
  chamado,
  dados,
  close,
  refresh,
  setRefresh,
  atualizarChamado,
}) {
  const [solucoesOptions, setSolucoesOptions] = useState([]);
  const [solucao, setSolucao] = useState("");
  const [descricao, setDescricao] = useState("");
  const [procedencia, setProcedencia] = useState("");


  const [arquivo, setArquivo] = useState("");

  const [errorSolucao, setErrorSolucao] = useState(false);
  const [errorDescricao, setErrorDescricao] = useState(false);
  const [errorProcedencia, setErrorProcedencia] = useState(false);

  const { handleSubmit, register } = useForm();

  function verificaError() {
    if (solucao === "" || solucao === "Selecione a solucao") {
      setErrorSolucao(true);
    } else {
      setErrorSolucao(false);
    }
    if (descricao === "") {
      setErrorDescricao(true);
    } else {
      setErrorDescricao(false);
    }
  }

  useEffect(() => {
    ticketConclusaoService.index().then((res) => {
      const dadosApi = Object.values(res.data);
      let solucoesOptionsAll = [];
      dadosApi.forEach((item) => {
        solucoesOptionsAll.push({
          value: item.id_ticket_conclusao_tipo,
          label: item.nome,
        });
      });
      setSolucoesOptions(solucoesOptionsAll);
    });
  }, []);

  const montaArquivo = useCallback((event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (ValidaTipoArquivo.verificaTipoArquivo(file)) {
      reader.onload = function (upload) {
        let dadosArquivo = {
          conteudo: upload.target.result.split(/base64,/)[1],
          nome: file.name,
        };
        setArquivo(dadosArquivo);
      };
      reader.readAsDataURL(file);
    } else {
      errorToast.fire({
        text: "Tipo de arquivo não suportado, selecione outro ou verifique o mesmo",
      });
    }
  }, []);

  function cadastraSolucao() {
    if (solucao === "" || solucao === "Selecione a solucao") {
      verificaError();
      return;
    }else{
      if (descricao === "") {
        verificaError();
        return;
      } else {
        Swal.fire({
          title: 'Envio de Mensagem de Conclusão',
          text: 'Será enviado uma mensagem para o cliente informando a conclusão do ticket. Deseja prosseguir?',
          icon: 'question',
          confirmButtonText: 'Sim, enviar e finalizar ticket',
          showDenyButton: true,
          denyButtonText: 'Não prosseguir',
          confirmButtonColor: 'var(--dommus-color)'
        }).then(resposta => {
          if(resposta.isConfirmed) {
            let dadosConcusao = {
              id_ticket_conclusao_tipo: solucao,
              descricao: descricao,
              procedencia_solicitacao: procedencia,
              data_conclusao: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
              dados_arquivo: arquivo,
            };
            trackPromise(
              ticketConclusaoService.store(dadosConcusao, chamado.id_ticket).then(
                (res) => {                  
                  close();
                  trackPromise(ticketService.update(chamado.id_ticket, dados).then(
                    (response) => {
                      Swal.fire({
                        titleText: "Chamado finalizado",
                        text: "Seu chamado foi concluído com sucesso!",
                        icon: "success",
                      }).then(() => {        
                    
                        setRefresh(!refresh);
                        if(atualizarChamado){
                          atualizarChamado(response.data)
                        }
                      });
                    },
                    (error) => {
                      Swal.fire({
                        titleText: "Ooops...",
                        text: "Ocorreu um erro ao salvar o chamado: " + error,
                        icon: "error",
                      });
                    }
                  ));
                },
                (error) => {
                  Swal.fire({
                    titleText: "Ooops...",
                    text: "Ocorreu um erro ao concluir o chamado: " + error,
                    icon: "error",
                  });
                }
              )
            );
          }
        })
      }
     }
    }
  

  return (
    <div>
      <div
        className="tituloNovoChamado"
        style={{ backgroundColor: "var(--dommus-color)" }}
      >
        <h5>Solução do Chamado</h5>
      </div>
      <Form
        className="novaIndicacaoForm"
        onSubmit={handleSubmit(cadastraSolucao)}
      >
        <Form.Group as={Col} controlId="formMotivo">
          <Form.Label>
            Solução: <DommusRequiredSpan />
          </Form.Label>
          <Form.Control
            onBlur={() => verificaError()}
            name="solucao"
            ref={register()}
            as="select"
            custom
            value={solucao}
            onChange={(event) => setSolucao(event.target.value)}
          >
            <option>Selecione a solucao</option>
            {solucoesOptions.map((campanha, index) => (
              <option key={index} value={campanha.value}>
                {campanha.label}
              </option>
            ))}
          </Form.Control>
          {errorSolucao ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="formDescricao">
          <Form.Label>
            Resposta que será encaminhada ao cliente, informado a conclusão do ticket: <DommusRequiredSpan />
          </Form.Label>
          <Form.Control
            onBlur={() => verificaError()}
            name="descricao"
            ref={register()}
            as="textarea"
            rows={10}
            value={descricao}
            onChange={(event) => setDescricao(event.target.value)}
          ></Form.Control>
          {errorDescricao ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>
        
      
        { chamado?.categoria?.tipo == 'AT' 
        && <>
        <Form.Group as={Col}>
        <FormControl>
          <Form.Label>Procedência</Form.Label>
          <RadioGroup
              row
              aria-labelledby="procedencia-row"
              name="row-radio-procedencia"
            >
            <FormControlLabel onChange={e => setProcedencia('P')} value="P" control={<Radio />} label="Procedente" />
            <FormControlLabel onChange={e => setProcedencia('I')} value="I" control={<Radio />} label="Improcedente" />
          </RadioGroup>
        </FormControl>
        {errorProcedencia ? (
            <p className="campoError">Campo Obrigatório</p>
          ) : (
            <></>
          )}
        </Form.Group>
        </>}

        <Form.Group as={Col} controlId="formNome">
          <div className="av-item select-file">
            <div className="documentacao-item-wrapper">
              <div className="form-group">
                <div>
                  <h6>Arquivo:</h6>
                </div>
                <div className="upload-wrapper">
                  <div id="documento-0" className="documento-wrapper">
                    <div className="ajax-upload-dragdrop">
                      <span>
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          className="form-control-file"
                          onChange={montaArquivo}
                        />
                      </span>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
        <Form.Group as={Col} controlId="formSubmit">
          <button
            className="btn btn-primary"
            type="submit"
            style={{
              backgroundColor: "var(--dommus-color)",
              alignSelf: "center",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Salvar
          </button>
        </Form.Group>
      </Form>
    </div>
  );
}
